import type { ReactNode } from 'react';

/**
 * Headline kinda badge like "HOLIDAY SPECIAL"
 */
export function GiftCardsBadge({ children }: { children: ReactNode }) {
  return (
    <span className="block uppercase tracking-[0.375rem] text-campaignAccent typo-caption-1 japanese:tracking-normal">
      {children}
    </span>
  );
}
