import type { SVGProps } from 'react';
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M21.626 3.429c-1.255-1.301-2.974-2.017-4.841-2.017-1.989 0-3.728 1.206-4.517 1.853a10 10 0 0 0-.649-.49 9 9 0 0 0-1.19-.695c-.921-.443-1.822-.668-2.679-.668-1.867 0-3.587.716-4.843 2.017C1.67 4.71.988 6.447.987 8.319c-.001 1.527.592 3.234 1.761 5.076.891 1.404 2.125 2.903 3.667 4.455a47 47 0 0 0 5.263 4.548 1 1 0 0 0 1.182-.002 48 48 0 0 0 5.26-4.565c3.602-3.635 5.427-6.835 5.424-9.512-.002-1.872-.683-3.609-1.918-4.89m-4.922 12.97a47 47 0 0 1-4.438 3.919 46 46 0 0 1-4.436-3.902C4.67 13.232 3 10.433 3.002 8.321 3.003 5.53 5.045 3.426 7.75 3.426c.552 0 1.159.158 1.804.469a7 7 0 0 1 1.278.801l-.364.389a1 1 0 0 0-.271.722 1 1 0 0 0 1.006.973c.277 0 .545-.116.736-.319l1.048-1.129c.865-.88 2.376-1.906 3.798-1.906 2.702 0 4.742 2.105 4.745 4.895.003 2.093-1.666 4.886-4.826 8.078"
    />
  </svg>
);
export default SvgHeart;
