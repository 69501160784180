import type { SVGProps } from 'react';
const SvgGift = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.377 2.755a1.54 1.54 0 0 0 0 3.081h3.24a8 8 0 0 0-.48-1.045c-.646-1.175-1.533-2.036-2.76-2.036m3.596 5.136v3.082H2.755V7.891zM4.129 5.836A3.596 3.596 0 0 1 7.377.7c2.369 0 3.794 1.707 4.56 3.1l.063.117.063-.117c.766-1.393 2.191-3.1 4.56-3.1a3.596 3.596 0 0 1 3.248 5.136h2.402c.567 0 1.027.46 1.027 1.028V12c0 .567-.46 1.027-1.027 1.027h-1.028v9.246c0 .567-.459 1.027-1.027 1.027H3.782c-.568 0-1.027-.46-1.027-1.027v-9.246H1.727C1.16 13.027.7 12.567.7 12V6.864c0-.568.46-1.028 1.027-1.028zm.68 7.191v8.219h6.164v-8.219zm8.218 0v8.219h6.164v-8.219zm8.218-2.054h-8.218V7.891h8.218zm-4.622-5.137a1.54 1.54 0 0 0 0-3.081c-1.227 0-2.114.861-2.76 2.036a8 8 0 0 0-.48 1.045z"
    />
  </svg>
);
export default SvgGift;
