import type { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M9.922 16.371a.53.53 0 0 0-.547.547v1.285c0 .328.219.547.547.547h4.156c.3 0 .547-.219.547-.547v-1.285a.55.55 0 0 0-.547-.547h-.547v-5.824a.55.55 0 0 0-.547-.547H9.922a.53.53 0 0 0-.547.547v1.312c0 .301.219.547.547.547h.547v3.965zM12 4.75a1.98 1.98 0 0 0-1.969 1.969A1.96 1.96 0 0 0 12 8.687a1.98 1.98 0 0 0 1.969-1.968c0-1.067-.903-1.969-1.969-1.969"
    />
  </svg>
);
export default SvgInfo;
